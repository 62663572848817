
import { mixin as clickaway } from "vue-clickaway";
import { mapGetters } from "vuex";
import CloseIcon from "@/assets/svg/heroicons/x-circle.svg?inline";
import AngleDown from "@/assets/svg/heroicons/angle-down.svg?inline";
import AngleUp from "@/assets/svg/heroicons/angle-up.svg?inline";

export default {
  components: {
    CloseIcon,
    AngleDown,
    AngleUp,
  },
  mixins: [clickaway],
  props: {
    color: {
      type: String,
      required: false,
      default: "white",
    },
    linkTitle: {
      type: String,
      required: false,
      default: "",
    },
    isProduct: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isOpen: false,
    scrollPosition: 0,
    menuSelected: null,
    itemSelected: null,
    isProductPage: false,
    isStorePage: false,
  }),
  computed: {
    ...mapGetters({
      products: "getProducts",
      categories: "getCategories",
    }),
    newTextClass() {
      if (
        this.$store.state.locale === "el" ||
        this.$store.state.locale === "ar"
      ) {
        return "xl:text-xs text-[11px] ";
      } else {
        return "xl:text-sm text-[11px] ";
      }
    },
  },
  watch: {
    $route() {
      this.away();

      if (window.location.href.includes("/products/")) {
        this.isProductPage = true;
      } else {
        this.isProductPage = false;
      }
      if (window.location.href.includes("/stores")) {
        this.isStorePage = true;
      } else {
        this.isStorePage = false;
      }
    },
    isOpen(val) {
      if (val) {
        this.menuSelected = this.products.length > 0 && this.products[0];

        this.itemSelected =
          this.menuSelected &&
          Object.keys(this.menuSelected.products).length > 0 &&
          this.menuSelected.products["All"][0];
      }
    },
  },
  mounted() {
    this.isProductPage = window.location.href.includes("/products/");
    this.isStorePage = window.location.href.includes("/stores");
    window.addEventListener("scroll", this.updateScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  methods: {
    hasSlot(name = "default") {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    away() {
      // document.body.style.overflowY = 'visible'
      this.isOpen = false;
      this.reset();
    },
    onHoverOpen() {
      // document.body.style.overflowY = 'hidden'
      this.$emit("color", "black");
      this.isOpen = !this.isOpen;

      if (!this.isOpen) {
        // document.body.style.overflowY = 'visible'
        this.reset();
      }
    },
    reset() {
      this.menuSelected = null;
      this.itemSelected = null;
      this.$emit("color", "white");
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
